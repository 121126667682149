import React, { Component } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';

import * as appUtils from './utils';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class PageVersionsGroups extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      versionsdata: null,
      versions_filter: {},
      groupsdata: null,
      groups_filter: {},

      vshowmodal: false,
      vmtitle: '',
      vmsavebutton: '',
      vmid: '',
      vmversion: '',
      vmrelease: null,
      vmgroup: '',
      vmtype: '',
      vmeid: -1,
      vmbodynew: '',
      vmbodyupgrade: '',
      vmbodyuserchange: '',

      gshowmodal: false,
      gmtitle: '',
      gmsavebutton: '',
      gmid: '',
      gmgroup: '',
      gmname: '',

      tshowmodal: false,
      ttitle: '',
      tsavebutton: '',
      temail: '',
      temailtype: '',

      bshowmodal: false,
      btitle: '',
      bsavebutton: '',
      bphrase: '',

      vversion: '',
      vleft: 0,
      vusers: 0,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.doFetchData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.doFetchData();
    }
  }

  ddmmmyyyy_to_date(s) {
    if (s === null || s === '') return null;
    var months = { jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5, jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11 };
    var p = s.split('-');
    return new Date(p[2], months[p[1].toLowerCase()], p[0]);
  }

  date_to_yyyymmdd(d) {
    if (d === null) return '';
    return d.toLocaleDateString('fr-CA');
  }

  render() {
    if (this.state.versionsdata == null || this.state.groupsdata == null) {
      return null;
    } else {
      const groupOptions = this.generateGroupList();
      const version_columns = [
        { dataField: 'version', text: 'Version', sort: true },
        { dataField: 'release', text: 'Release', sort: true },
        {
          dataField: 'grouptxt',
          text: 'Group',
          sort: true,
          filter: selectFilter({ options: this.state.versions_filter }),
        },
        { dataField: 'type', text: 'Type', sort: true },
        { dataField: 'hasnewemail', text: 'Email (new)', sort: true },
        { dataField: 'hasuserchangemail', text: 'Email (user change)', sort: true },
        { dataField: 'hasupgradeemail', text: 'Email (upgrade)', sort: true },
      ];
      const group_columns = [
        { dataField: 'group', text: 'Group', sort: true },
        { dataField: 'name', text: 'Name', sort: true },
      ];
      return (
        <div className="page">
          <h2>Versions/groups</h2>
          <div className="adminblock">
            <div className="adminheader">
              <h3>{this.props.account} app versions</h3>
            </div>
            <div className="adminbuttonleft">
              <Button
                onClick={() => {
                  var selGroup = this.generateGroupList(0);
                  this.setState({
                    vshowmodal: true,
                    vmtitle: 'Create new version',
                    vmsavebutton: 'Create version',
                    vmid: -1,
                    vmversion: '',
                    vmrelease: '',
                    vmgroup: selGroup,
                    vmtype: 0,
                    vmeid: -1,
                    vmbodynew: '',
                    vmbodyupgrade: '',
                    vmbodyuserchange: '',
                  });
                }}
              >
                add version
              </Button>
            </div>
            <div className="adminbuttonright">
              <Button
                onClick={() => {
                  this.setState({
                    bshowmodal: true,
                    btitle: 'Bulk send upgrade emails',
                    bsavebutton: 'Send',
                    bphrase: '',
                  });
                }}
                disabled={
                  this.state.versionsdata != null &&
                  this.state.versionsdata[0] != null &&
                  this.state.versionsdata[0].body_upgrade.length < 1
                }
              >
                Bulk send upgrade emails
              </Button>
            </div>
            <div className="adminbuttonright">
              <Button
                onClick={() => {
                  this.setState({
                    tshowmodal: true,
                    ttitle: 'Send test email (upgrade)',
                    tsavebutton: 'Send',
                    temail: '',
                    temailtype: 'upgrade',
                  });
                }}
                disabled={
                  this.state.versionsdata != null &&
                  this.state.versionsdata[0] != null &&
                  this.state.versionsdata[0].body_upgrade.length < 1
                }
              >
                Test upgrade
              </Button>
            </div>
            <div className="adminbuttonright">
              <Button
                onClick={() => {
                  this.setState({
                    tshowmodal: true,
                    ttitle: 'Send test email (user change)',
                    tsavebutton: 'Send',
                    temail: '',
                    temailtype: 'user_change',
                  });
                }}
                disabled={
                  this.state.versionsdata != null &&
                  this.state.versionsdata[0] != null &&
                  this.state.versionsdata[0].body_user_change.length < 1
                }
              >
                Test user change
              </Button>
            </div>
            <div className="adminbuttonright">
              <Button
                onClick={() => {
                  this.setState({
                    tshowmodal: true,
                    ttitle: 'Send test email (new)',
                    tsavebutton: 'Send',
                    temail: '',
                    temailtype: 'new',
                  });
                }}
                disabled={
                  this.state.versionsdata != null &&
                  this.state.versionsdata[0] != null &&
                  this.state.versionsdata[0].body_new.length < 1
                }
              >
                Test new
              </Button>
            </div>
            <div className="admintable" />
            <BootstrapTable
              striped
              hover
              keyField="row"
              version="4"
              columns={version_columns}
              data={this.state.versionsdata}
              filter={filterFactory()}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  //console.log(row);
                  var selGroup = this.generateGroupList(row.group);
                  this.setState({
                    vshowmodal: true,
                    vmtitle: 'Update version',
                    vmsavebutton: 'Update version',
                    vmid: row.id,
                    vmversion: row.version,
                    vmrelease: row.release,
                    vmgroup: selGroup,
                    vmtype: row.type,
                    vmeid: row.eid,
                    vmbodynew: row.body_new,
                    vmbodyupgrade: row.body_upgrade,
                    vmbodyuserchange: row.body_user_change
                  });
                },
              }}
            />
          </div>

          <div className="adminblock">
            <div className="adminheader">
              <h3>{this.props.account} app version groups</h3>
            </div>
            <div className="adminbutton">
              <Button
                onClick={() => {
                  this.setState({
                    gshowmodal: true,
                    gmtitle: 'Create new group',
                    gmsavebutton: 'Create group',
                    gmid: -1,
                    gmgroup: 0,
                    gmname: '',
                  });
                }}
              >
                add group
              </Button>
            </div>
            <div className="admintable" />
            <BootstrapTable
              striped
              hover
              keyField="row"
              version="4"
              columns={group_columns}
              data={this.state.groupsdata}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  //console.log(row);
                  this.setState({
                    gshowmodal: true,
                    gmtitle: 'Update group',
                    gmsavebutton: 'Update group',
                    gmid: row.id,
                    gmgroup: row.group,
                    gmname: row.name,
                  });
                },
              }}
            />
          </div>

          <Modal show={this.state.bshowmodal} onHide={() => this.modalBClose()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.btitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <p>{'Latest version is ' + this.state.vversion + ' with ' + this.state.vleft + ' vouchers left.'}</p>
                <p>{'We need to send ' + this.state.vusers + ' vouchers.'}</p>
                <Form.Group controlId="formBasicName">
                  <Form.Label>
                    Since this operation can potentially send a lot of emails, please enter 'CONFIRM' in the field below
                    to confirm sending
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={this.state.bphrase}
                    onChange={this.handleBPhraseChange.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.modalBClose()}>
                Close
              </Button>
              <Button variant="primary" onClick={() => this.modalBSave()}>
                {this.state.bsavebutton}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.tshowmodal} onHide={() => this.modalTClose()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.ttitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <p>Please enter email address where you want to send test email.</p>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email address"
                    value={this.state.temail}
                    onChange={this.handleTEmailChange.bind(this)}
                  />
                </Form.Group>
              </Form>
              <p>Note: Test email will use next %voucher% if needed but it won't be spent and will be re-used when sending non-test email.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.modalTClose()}>
                Close
              </Button>
              <Button variant="primary" onClick={() => this.modalTSave()}>
                {this.state.tsavebutton}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.gshowmodal} onHide={() => this.modalGClose()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.gmtitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formBasicGroup">
                  <Form.Label>type</Form.Label>
                  <Form.Control
                    type="number"
                    step="1"
                    placeholder="Group"
                    value={this.state.gmgroup}
                    onChange={this.handleGGroupChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={this.state.gmname}
                    onChange={this.handleGNameChange.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.modalGClose()}>
                Close
              </Button>
              <Button variant="primary" onClick={() => this.modalGSave()}>
                {this.state.gmsavebutton}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.vshowmodal} onHide={() => this.modalVClose()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.vmtitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formBasicVersion">
                  <Form.Label>Version</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Version"
                    value={this.state.vmversion}
                    onChange={this.handleVVersionChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicRelease">
                  <Form.Label>Release</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    value={this.date_to_yyyymmdd(this.ddmmmyyyy_to_date(this.state.vmrelease))}
                    onChange={this.handleVReleaseChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formGridGroup">
                  <Form.Label>Member of group</Form.Label>
                  <Select
                    options={groupOptions}
                    value={this.state.vmgroup}
                    onChange={this.handleVGroupChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicType">
                  <Form.Label>type</Form.Label>
                  <Form.Control
                    type="number"
                    step="1"
                    placeholder="Type"
                    value={this.state.vmtype}
                    onChange={this.handleVTypeChange.bind(this)}
                  />
                </Form.Group>
                <hr />
                <Form.Label>Email definitions, first template line is subject, rest is email body!</Form.Label>
                <ul>
                  <li>%client%</li>
                  <li>%app%</li>
                  <li>%user%</li>
                  <li>%password%</li>
                  <li>%voucher%</li>
                </ul>
                <Form.Group controlId="formEmailBodyNew">
                  <Form.Label>Email sent to new users:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    type="text"
                    placeholder="First line is subject!&#10;Email body starts on second line."
                    value={this.state.vmbodynew}
                    onChange={this.handleVBodyNewChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formEmailUserChange">
                  <Form.Label>Email sent when user data changes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    type="text"
                    placeholder="First line is subject!&#10;Email body starts on second line."
                    value={this.state.vmbodyuserchange}
                    onChange={this.handleVBodyUserChangeChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group controlId="formEmailBodyUpgrade">
                  <Form.Label>Upgrade email:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    type="text"
                    placeholder="First line is subject!&#10;Email body starts on second line."
                    value={this.state.vmbodyupgrade}
                    onChange={this.handleVBodyUpgradeChange.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.modalVClose()}>
                Close
              </Button>
              <Button variant="primary" onClick={() => this.modalVSave()}>
                {this.state.vmsavebutton}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

  generateGroupList(groupid = null) {
    var groupOptions = [{ label: 'none', value: 0 }];
    if (this.state.groupsdata != null) {
      for (var opt of this.state.groupsdata) {
        groupOptions.push({ label: opt.name, value: opt.group });
      }
    }
    if (groupid === null) return groupOptions;
    let found = groupOptions.filter((item, index, array) => {
      return item.value === groupid;
    });
    if (found.length !== 1) {
      return groupOptions[0];
    } else {
      return found[0];
    }
  }

  modalBClose() {
    this.setState({ bshowmodal: false });
  }

  modalTClose() {
    this.setState({ tshowmodal: false });
  }

  modalVClose() {
    this.setState({ vshowmodal: false });
  }

  modalGClose() {
    this.setState({ gshowmodal: false });
  }

  modalVSave() {
    this.setState({ vshowmodal: false });
    /*
    console.log('saving');
    console.log(JSON.stringify(this.state.vmid, null, 4));
    console.log(JSON.stringify(this.state.vmversion, null, 4));
    console.log(JSON.stringify(this.state.vmrelease, null, 4));
    console.log(JSON.stringify(this.state.vmgroup.value, null, 4));
    console.log(JSON.stringify(this.state.vmtype, null, 4));
    console.log(JSON.stringify(this.state.vmbody, null, 4));
    console.log(JSON.stringify(this.state.vmeid, null, 4));
    */
    let messageFinal = JSON.stringify({
      id: this.state.vmid,
      version: this.state.vmversion,
      release: this.state.vmrelease,
      group: this.state.vmgroup.value,
      type: this.state.vmtype,
      eid: this.state.vmeid,
      bodynew: this.state.vmbodynew,
      bodyupgrade: this.state.vmbodyupgrade,
      bodyuserchange: this.state.vmbodyuserchange
    });
    var url = appUtils.apiBaseURL() + 'post_version_version';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }

  modalGSave() {
    this.setState({ gshowmodal: false });
    /*
    console.log('saving');
    console.log(JSON.stringify(this.state.gmid, null, 4));
    console.log(JSON.stringify(this.state.gmgroup, null, 4));
    console.log(JSON.stringify(this.state.gmname, null, 4));
    */
    let messageFinal = JSON.stringify({
      id: this.state.gmid,
      group: this.state.gmgroup,
      name: this.state.gmname,
    });
    var url = appUtils.apiBaseURL() + 'post_version_group';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }

  groupForGroup(data, group) {
    let found = data.filter((item, index, array) => {
      return item.group === group;
    });
    if (found.length !== 1) {
      return group.toString();
    } else {
      return group.toString() + ' - ' + found[0].name;
    }
  }

  doFetchDataVouchers() {
    var url = appUtils.apiBaseURL() + 'vouchers_left';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            if (this.mounted)
              this.setState({
                vversion: data.version,
                vleft: data.left,
                vusers: data.users,
              });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }

  doFetchData(startDate, endDate) {
    this.doFetchDataVouchers();
    var url = appUtils.apiBaseURL() + 'versions_groups';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let versions = data['versions'].map((val, ix) => {
              val.row = ix;
              val.grouptxt = this.groupForGroup(data['groups'], val.group);
              val.body_new = val.body_new == null ? '' : val.body_new;
              val.hasnewemail = val.body_new.length < 1 ? '✗' : '✓';
              val.body_user_change = val.body_user_change == null ? '' : val.body_user_change;
              val.hasuserchangemail = val.body_user_change.length < 1 ? '✗' : '✓';
              val.body_upgrade = val.body_upgrade == null ? '' : val.body_upgrade;
              val.hasupgradeemail = val.body_upgrade.length < 1 ? '✗' : '✓';
              val.eid = val.eid == null ? -1 : val.eid;
              return val;
            });
            //console.log(JSON.stringify(data, null, 4));
            let groups = data['groups'].map((val, ix) => {
              val.row = ix;
              return val;
            });
            var versions_filter = {};
            for (var x in versions) {
              //versions_filter[versions[x].group] = versions[x].group;
              let group = this.groupForGroup(data['groups'], versions[x].group);
              versions_filter[group] = group;
            }
            //console.log(JSON.stringify(versions[0], null, 4));
            if (this.mounted)
              this.setState({
                versionsdata: versions,
                groupsdata: groups,
                versions_filter: versions_filter,
              });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }

  handleVVersionChange(e) {
    this.setState({ vmversion: e.target.value });
  }

  handleVBodyNewChange(e) {
    this.setState({ vmbodynew: e.target.value });
  }

  handleVBodyUpgradeChange(e) {
    this.setState({ vmbodyupgrade: e.target.value });
  }

  handleVBodyUserChangeChange(e) {
    this.setState({ vmbodyuserchange: e.target.value });
  }

  yyyymmdd_to_date(s) {
    if (s === null || s === '') return null;
    var p = s.split('-');
    return new Date(p[0], p[1] - 1, p[2]);
  }

  date_to_ddmmmyyyy(d) {
    if (d === null) return '';
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    d.setDate(d.getDate() - 1);
    return d.getDate() + 1 + '-' + monthNames[d.getMonth()] + '-' + d.getFullYear();
  }

  handleVReleaseChange(e) {
    var d = this.date_to_ddmmmyyyy(this.yyyymmdd_to_date(e.target.value));
    this.setState({ vmrelease: d });
  }

  handleVGroupChange(e) {
    this.setState({ vmgroup: e });
  }

  handleVTypeChange(e) {
    //TODO: this int handling can probably be better
    var num = parseInt(e.target.value) || 0;
    this.setState({ vmtype: num });
  }

  handleGGroupChange(e) {
    //TODO: this int handling can probably be better
    var num = parseInt(e.target.value) || 0;
    this.setState({ gmgroup: num });
  }

  handleGNameChange(e) {
    this.setState({ gmname: e.target.value });
  }

  handleTEmailChange(e) {
    this.setState({ temail: e.target.value });
  }

  handleBPhraseChange(e) {
    this.setState({ bphrase: e.target.value });
  }

  modalTSave() {
    this.setState({ tshowmodal: false });
    //console.log(this.state.temail);
    let messageFinal = JSON.stringify({
      email: this.state.temail,
      emailtype: this.state.temailtype,
    });
    var url = appUtils.apiBaseURL() + 'post_test_email';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }

  modalBSave() {
    this.setState({ bshowmodal: false });
    //console.log(this.state.bphrase);
    if (this.state.bphrase.toUpperCase() !== 'CONFIRM') {
      window.alert(
        "You didn't type proper confirmation phrase so we won't send anything. Please try again if you really want to send upgrade emails."
      );
      return;
    } else {
      window.alert('Sending...');
    }
    let messageFinal = JSON.stringify({
      confirm: 'confirm'
    });
    var url = appUtils.apiBaseURL() + 'post_bulk_email';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            this.doFetchData();
          });
        } else {
          console.log('error 1');
        }
      })
      .catch((err) => {
        console.log('error 2');
      });
  }
}

export default PageVersionsGroups;
